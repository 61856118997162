<template>
  <a
    ref="button"
    :href="link"
    class="button"
    :target="outbound ? '_blank' : ''"
    :rel="outbound ? 'noopener noreferrer' : ''"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "Button",
  props: {
    link: { default: "#!" },
    outbound: { default: false },
    download: { default: false },
  },
  mounted() {
    if (this.download) this.$refs.button.setAttribute("download", "")
  },
}
</script>

<style lang="scss" scoped>
.button {
  max-width: 25rem;
  background: #4aa846;
  background-size: 200% 110%;
  background-position: right bottom;
  border-radius: 50rem;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  padding: 1.6rem 2.4rem;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  overflow: hidden;

  transition: all $basic-trans-200;

  &:hover {
    background: rgb(75, 138, 25);
  }

  @include small-up {
    max-width: rs(250);
    border-radius: rs(500);
    padding: rs(12) rs(24);
  }
}
</style>
