<template>
  <div class="socials gui__element gui__element--center-right" v-if="tour">
    <a
      v-if="tour.client.facebook"
      :href="tour.client.facebook"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icons/icon-facebook.svg`" alt="Facebook icon" />
    </a>
    <a
      v-if="tour.client.linkedin"
      :href="tour.client.linkedin"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icons/icon-linkedin.svg`" alt="Linkedin icon" />
    </a>
    <a
      v-if="tour.client.instagram"
      :href="tour.client.instagram"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icons/icon-instagram.svg`" alt="Instagram icon" />
    </a>
    <a
      v-if="tour.client.youtube"
      :href="tour.client.youtube"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icons/icon-youtube.svg`" alt="Youtube icon" />
    </a>
    <a
      v-if="tour.client.twitter"
      :href="tour.client.twitter"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icons/icon-twitter.svg`" alt="Twitter icon" />
    </a>
  </div>
</template>

<script>
export default {
  name: "Socials",
  computed: {
    tour() {
      return this.$store.state.currentTour;
    },
  },
};
</script>

<style lang="scss">
.socials {
  display: grid;
  flex-direction: column;
  grid-gap: 1.2rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    position: relative;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      transition: opacity $basic-trans-200;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(50deg)
        brightness(110%) contrast(100%);
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  @include small-up {
    grid-gap: rs(16);

    a {
      width: rs(32);
      height: rs(32);
    }
  }
}
</style>
