<template>
  <div class="krpano__wrapper" @keydown.esc="modal = false">
    <transition-group name="splash-fade" mode="out-in">
      <SplashScreen
        v-if="showingSplash && loading"
        @close="showingSplash = false"
        key="splash"
      />
      <TutorialScreen
        v-if="showTutorial && !showingSplash && !loading"
        @close="showTutorial = false"
        key="tutorial"
      />
    </transition-group>
    <Modal
      class="modal"
      :open="modal"
      :content="modalContent"
      @close="modal = false"
    />
    <!-- <SideMenu
      class="nav__side-menu"
      :open="sideMenu"
      @close="sideMenu = false"
    /> -->
    <Gallery class="nav__gallery" :open="gallery" @close="gallery = false" />

    <transition name="loading-fade">
      <Loader v-if="loading" />
    </transition>

    <!-- GUI -->
    <transition name="gui-fade">
      <div class="gui" v-if="!modal && !gallery && !sideMenu && !showTutorial">
        <Socials v-if="!$embed" />
        <Languages class="languages gui__element gui__element--top-right" />

        <a
          v-if="!$embed && $store.state.currentTour"
          class="gui__logo gui__element gui__element--top-left"
          :href="`/${$locale}`"
          ><img :src="$l.logo" alt="Client logo"
        /></a>

        <Button
          v-if="webVRCapable && !webVRActive"
          class="gui__webvr gui__element gui__element--top-center"
          @click.native.prevent="startWebVR"
        >
          {{ $t.uiEnterVr }}
        </Button>

        <div
          v-if="$store.state.currentPano"
          class="gui__title gui__element gui__element--bottom-left"
        >
          <h1>{{ $store.state.currentPano.title }}</h1>
          <!-- <h2>{{ $store.state.currentTour.title }}</h2> -->
          <ReadMore
            v-if="$store.state.currentPano.description"
            :text="$store.state.currentPano.description"
            :max="150"
          />
        </div>

        <!-- <a
          href="#!"
          class="side-menu__button gui__element gui__element--top-right"
          @click.prevent="sideMenu = true"
        >
          <div class="side-menu__button-icon">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </a> -->

        <Dots class="gui__element gui__element--bottom-center" />
        <Actions @open-gallery="gallery = true" />
      </div>
    </transition>

    <!-- Krpano target -->
    <div id="krpano"></div>
  </div>
</template>

<script>
// Imports
import Vue from "vue"

// Components
import SplashScreen from "@/components/SplashScreen.vue"
import TutorialScreen from "@/components/TutorialScreen.vue"
import Loader from "@/components/Loader.vue"
import Gallery from "@/components/Gallery.vue"
// import SideMenu from "@/components/SideMenu.vue";
import Actions from "@/components/Actions.vue"
import Dots from "@/components/Dots.vue"
import Hotspot from "@/components/Hotspot.vue"
import Modal from "@/components/Modal.vue"
import Socials from "@/components/Socials.vue"
import ReadMore from "@/components/ReadMore.vue"
import Button from "@/components/Button.vue"
import Languages from "@/components/Languages.vue"

// Helpers
import {
  isTouchDevice,
  addExitVrHotspot,
  addVrHotspots,
  removeVrHotspots,
} from "@/helpers"

// API
import virtualTourApi from "@/api"

// Setup
export default {
  name: "Krpano",
  props: ["info"],
  components: {
    SplashScreen,
    TutorialScreen,
    Loader,
    Languages,
    Gallery,
    // SideMenu,
    Actions,
    Dots,
    Hotspot,
    Modal,
    Socials,
    ReadMore,
    Button,
  },
  data() {
    return {
      // GUI
      loading: true,
      gallery: false,
      sideMenu: false,
      modal: false,
      modalContent: {},
      showTutorial: localStorage.getItem("tutorialShown")
        ? false
        : this.$t.tutorialDisplay,
      showingSplash: true,
      // VR
      webVRCapable: false,
      webVRActive: false,
      // Krpano options
      krpanoOptions: {
        id: "krpanoObject",
        target: "krpano",
        html5: "only+webgl",
        mobilescale: 1.0,
        wmode: "opaque",
        consolelog: process.env.NODE_ENV === "development",
        mwheel: true,
        onready: (krpano) => {
          window.krpanoObj = krpano.get("global")
          if (isTouchDevice()) {
            krpanoObj.control.mouse = "drag"
            krpanoObj.control.touch = "drag"
          } else {
            krpanoObj.control.mouse = "follow"
            krpanoObj.control.touch = "follow"
          }
        },
      },
    }
  },
  computed: {
    pageTitle() {
      if (this.$store.state.currentTour && this.$store.state.currentPano) {
        return `${this.$store.state.currentPano.title} - ${this.$store.state.currentTour.title}`
      }
      return "Virtual tour"
    },
  },
  metaInfo() {
    return {
      title: this.pageTitle,
    }
  },
  mounted() {
    Vue.prototype.$loadPano = this.loadPano
    // if (this.$route.params.tour) {
    this.fetchPanos()
    // }
  },
  beforeRouteUpdate(to, from, next) {
    // Back to previous pano
    const isBackButton = window.popStateDetected
    window.popStateDetected = false
    const prevPano = this.$store.state.currentTour.panos.find(
      (p) => p.slug == to.params.panorama
    )

    if (isBackButton) {
      if (prevPano && prevPano !== this.$store.state.currentPano) {
        this.loadPano(prevPano, true)
      } else {
        history.back()
      }
    }
    next()
  },
  methods: {
    initWindow() {
      // Init loader
      window.startLoading = () => {
        this.loading = true
      }
      window.stopLoading = () => {
        this.loading = false
        this.showingSplash = false
      }

      // Back button
      window.popStateDetected = false
      window.addEventListener("popstate", () => {
        window.popStateDetected = true
      })

      // Web VR init
      window.setVrReadyStatus = (vrStatus) => {
        this.webVRCapable = vrStatus
      }

      // Init hotspots
      window.loadHotspot = async (spot, pano) => {
        let hotspot = (
          await virtualTourApi.getHotspot(
            this.$store.state.currentTour.id,
            pano.name,
            spot.name
          )
        ).data
        if (hotspot.linkedScene || hotspot.linkedPano) {
          let linkedPano = (
            await virtualTourApi.getPano(
              this.$store.state.currentTour.id,
              hotspot.linkedPano
                ? hotspot.linkedPano.panoName
                : hotspot.linkedScene
            )
          ).data
          this.loadHotspotStyle(spot, hotspot, linkedPano)
        } else {
          this.loadHotspotStyle(spot, hotspot, null)
        }
      }

      // Handle VR hotspot click
      window.handleVrHotspotClick = (type, title, slug, name) => {
        switch (type) {
          case "navigation":
            this.loadPano({ title: title, name: name, slug: slug })
            break
          case "exit_vr":
            this.exitWebVR()
            break
          default:
            break
        }
      }
    },
    async fetchPanos() {
      virtualTourApi
        .getPanosForTour()
        .then((res) => {
          // Set tour
          this.$store.state.currentTour = res.data

          // Init window
          this.initWindow()

          // Set Categories
          virtualTourApi
            .getCategories(this.$store.state.currentTour.id)
            .then((res) => {
              this.$store.state.currentCategories = res.data
            })
            .catch((err) => console.log(err))

          // Set pano
          this.setPano(this.$route.params.panorama)

          // Initiate Krpano
          this.initTour()
        })
        .catch((err) => console.log(err))
    },
    setPano(panoSlug) {
      const panos = this.$store.state.currentTour.panos
      if (panoSlug) {
        this.$store.state.currentPano = panos.find((p) => p.slug == panoSlug)
      } else {
        this.$store.state.currentPano = panos[0] ? panos[0] : null
      }
    },
    initTour() {
      const tour = this.$store.state.currentTour
      const pano = this.$store.state.currentPano

      if (tour && pano) {
        const xml = `/assets/vtours/${tour.id}/tour.xml?h=${+new Date()}`
        const { embedpano } = window

        embedpano({
          xml: xml,
          ...this.krpanoOptions,
          initvars: {
            basePath: "/assets/js",
            partialPath: "/assets/js/partials",
            pluginPath: "/assets/js/plugins",
            assetPath: "/assets/js/assets",
            dev: process.env.NODE_ENV === "development",
            primaryColor: this.$l.primaryColor.replace("#", "0x"),
            secondaryColor: this.$l.secondaryColor.replace("#", "0x"),
            accentColor: this.$l.accentColor.replace("#", "0x"),
          },
        })

        if (tour.panos.indexOf(pano) !== 0) {
          this.loadPano(pano)
        } else {
          const panoPath = `/${this.$locale}/${pano.slug}`

          // First pano
          if (!this.$route.params.panorama) {
            // If no slug, rewrite url
            this.$router.push(panoPath)
          }

          // Send GTM event for first pano
          this.sendGtmView(`${pano.title}`, panoPath)
        }
      }
    },
    loadPano(pano, retrace = false) {
      if (!pano) return
      const tour = this.$store.state.currentTour

      // Load scene
      window.krpanoObj.call(
        `loadscene('${pano.name}', null, MERGE, BLEND(0.5));`
      )

      // Update state
      this.$store.state.currentPano = pano

      // Send view to GTM
      const panoPath = `/${this.$locale}/${pano.slug}`
      this.sendGtmView(`${pano.title}`, panoPath)

      // Update router history
      if (this.$route.params.panorama !== pano.slug && !retrace) {
        this.$router.push(panoPath)
      }

      // Check if VR is active
      if (this.webVRActive) {
        addExitVrHotspot(this.$t.uiExitVr)
      }
    },
    async loadHotspotStyle(spot, hotspot, panorama) {
      hotspot.linkedPano = panorama

      let template = `
      <div class="hotspot__wrapper">
        <div id="hotspot-${hotspot.id}"></div>
        <div class="hotspot__outer"></div>
      </div>
      `

      // Set basic config
      spot.type = "text"
      spot.renderer = "css3d"
      spot.html = template
      spot.zoom = false
      spot.distorted = false

      if (spot.sprite) {
        // Set the id of the moving hotspot
        spot.sprite.id = `hotspot__locator--${hotspot.id}`

        // Init hotspot component onto template
        const HotspotCtor = Vue.extend(Hotspot)
        const hotspotElement = new HotspotCtor({
          propsData: {
            webVRActive: this.webVRActive,
            hotspot: hotspot,
          },
          parent: this,
        }).$mount(`#hotspot-${hotspot.id}`)

        // Set click action
        spot.onclick = () => {
          hotspotElement.handleClick()
        }

        // Listen to hotspot
        hotspotElement.$on("navigate", (linkedPano) => {
          this.loadPano(linkedPano)
        })
        hotspotElement.$on("info", (content) => {
          this.modalContent = content
          this.modal = true
        })
      }
    },
    startWebVR() {
      addVrHotspots({
        continue: this.$t.uiContinueLabel,
        exit: this.$t.uiExitVr,
      })
      this.webVRActive = true
      krpanoObj.call("webvr_onentervr();")
      krpanoObj.call("webvr.enterVR();")
      this.sendGtmEvent("webvr-open", {
        webvr_pano: this.$store.state.currentPano.title,
      })
    },
    exitWebVR() {
      removeVrHotspots()
      this.webVRActive = false
      krpanoObj.call("webvr.exitVR();")
      krpanoObj.call("webvr_onexitvr();")
      krpanoObj.call(
        `lookto(${this.$store.state.currentPano.ath}, ${this.$store.state.currentPano.atv})`
      )
    },
  },
}
</script>

<style lang="scss">
@import "../scss/gui.scss";
@import "../scss/components/krpano.scss";
@import "../scss/components/hotspot/hotspot-outer.scss";
</style>
