<template>
  <div>
    <a
      v-for="locale in availableLocales"
      :key="locale.key"
      @click.prevent="selectLocale(locale)"
      :class="locale.key === selected ? 'selected' : ''"
    >
      {{ locale.label }}
    </a>
  </div>
</template>

<script>
import virtualTourApi from "@/api";

export default {
  name: "Languages",
  data() {
    return {
      selected: localStorage.getItem("locale")
        ? localStorage.getItem("locale")
        : this.$locale,
      availableLocales: [],
    };
  },
  mounted() {
    const params = {
      vtourId: this.$store.state.currentTour
        ? this.$store.state.currentTour.id
        : null,
      panoId: this.$store.state.currentPano
        ? this.$store.state.currentPano.id
        : null,
    };

    virtualTourApi
      .getAvailableLocales(params.vtourId, params.panoId)
      .then((res) => (this.availableLocales = res.data));
  },
  methods: {
    selectLocale(locale) {
      this.selected = locale.key;
      localStorage.setItem("locale", locale.key);
      this.$locale = locale.key;

      let redirectPath = `${process.env.VUE_APP_BASE_URL}${locale.key}`;
      // if (this.$store.state.currentTour && this.$store.state.currentPano) {
      //   redirectPath += `/${locale.panoSlug}`;
      // }
      window.location.href = redirectPath;
    },
  },
};
</script>

<style lang="scss">
.languages {
  padding: rs(2);
  display: flex;
  flex-direction: column;
  color: $color-text-inverse;
  z-index: 1001;

  a {
    cursor: pointer;
    opacity: 0.9;
    text-transform: uppercase;
    margin: 0.6rem 0;
    color: $color-white;

    & ~ a::before {
      content: "";
      margin: 0;
      pointer-events: none;
      font-weight: normal !important;
    }

    &.selected {
      pointer-events: none;
    }

    &.selected,
    &:hover {
      opacity: 1;
      font-weight: bold;
    }

    &:hover::before {
      font-weight: normal !important;
      opacity: 0.9;
    }
  }

  @include small-up {
    flex-direction: row;

    a {
      margin: 0;

      & ~ a::before {
        content: "|";
        margin: 0 rs(6);
      }
    }
  }
}
</style>
