<template>
  <div :class="`modal__container ${open ? 'modal__container_open' : ''}`">
    <div
      :class="`modal__inner ${
        content.images || content.hasVideo ? '-has-media' : ''
      }`"
    >
      <a
        class="btn--menu modal--close gui__element gui__element--top-right"
        href="#!"
        @click.prevent="$emit('close')"
      >
        <img :src="`/assets/img/icons/icon-close.svg`"
      /></a>

      <div class="modal__content">
        <h2 class="modal__content__title" v-if="content.title">
          {{ content.title }}
        </h2>

        <!-- Video -->
        <div
          :class="`video ${content.content ? '-margin' : ''}`"
          v-if="
            open &&
            content.hasVideo &&
            content.videoUrl &&
            content.videoType.value
          "
        >
          <iframe
            v-if="content.videoType.value.toLowerCase() == 'youtube'"
            :src="`https://www.youtube.com/embed/${parseYoutubeLink(
              content.videoUrl
            )}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            v-if="content.videoType.value.toLowerCase() == 'vimeo'"
            title="vimeo-player"
            :src="`https://player.vimeo.com/video/${parseVimeoLink(
              content.videoUrl
            )}`"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>

        <div class="modal__content__inner">
          <div class="text" v-if="content.content">
            <div v-html="content.content"></div>
            <Button :href="content.externalLink" v-if="content.externalLink">
              {{
                content.externalLinkTitle
                  ? content.externalLinkTitle
                  : "Open external link"
              }}
              <img :src="`/assets/img/icons/icon-link.svg`" alt="link icon" />
            </Button>
          </div>

          <div class="media" v-if="content.images">
            <!-- Images -->
            <Flicking
              v-if="content.images && content.images.length > 1"
              :options="flickingOptions"
              ref="flick"
              class="modal__content__gallery"
            >
              <div
                v-for="(image, index) in content.images"
                :key="image"
                class="img__wrapper"
                @click="tinyIndex = index"
              >
                <img :src="image" alt="Modal image" />
              </div>
            </Flicking>
            <div
              v-if="content.images && content.images.length === 1"
              class="img__wrapper -single"
              @click="tinyIndex = 0"
            >
              <img :src="content.images[0]" alt="Modal image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Tinybox
      v-if="content.images"
      v-model="tinyIndex"
      :images="content.images"
      no-thumbs
      loop
    />
    <div class="modal__outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import { parseYoutubeLink, parseVimeoLink } from "@/helpers"
import { Flicking } from "@egjs/vue-flicking"
import "@egjs/vue-flicking/dist/flicking.css"
import "@egjs/vue-flicking/dist/flicking-inline.css"
import Button from "@/components/Button.vue"
import Tinybox from "vue-tinybox"

export default {
  name: "Modal",
  components: {
    Button,
    Flicking: Flicking,
    Tinybox,
  },
  props: {
    content: Object,
    open: Boolean,
    close: Function,
  },
  data() {
    return {
      tinyIndex: null,
      flickingOptions: {
        align: "prev",
        bounce: 0,
      },
    }
  },
  methods: {
    parseYoutubeLink: parseYoutubeLink,
    parseVimeoLink: parseVimeoLink,
  },
}
</script>

<style lang="scss" scoped>
.modal {
  &__container {
    display: grid;
    position: absolute;
    background: rgba($color-black, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-trans-500 0.1s;

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .modal__inner {
        opacity: 1;
        top: unset;
        bottom: 0;
        transform: translateX(-50%);
        visibility: visible;

        width: 100%;
        height: auto;
        max-height: 75%;

        &.-has-media {
          max-height: 100%;
          height: 100%;
        }

        .modal__content {
          opacity: 1;
        }

        @include small-up {
          width: 70%;
          height: auto;
          max-height: 80%;
          max-width: rs(1000);
          border-radius: rs(4);
          bottom: unset;
          top: 50%;
          transform: translate(-50%, -50%);

          &:not(.-has-media) {
            max-width: 20vw;
          }

          &.-has-media {
            height: auto;
            max-height: 80%;
          }
        }
      }

      .modal__outer {
        pointer-events: all;
        opacity: 0.3;
      }
    }
  }

  &__inner {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1001;
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: height 0.3s linear 0.1s, width 0.3s linear 0.1s,
      opacity $basic-trans-500 0.1s, transform $basic-trans-500 0.1s;
    line-height: 1.4;
    border-radius: 0.4rem 0.4rem 0 0;
    font-size: 1.6rem;
    overflow-x: hidden;

    * {
      transition: none !important;
    }

    a.modal--close {
      right: 0.6rem;
      top: 0.6rem;
    }

    .modal__content {
      overflow: auto;
      width: 100%;
      opacity: 0;
      transition: opacity $basic-trans-300 0.5s;
    }

    @include small-up {
      width: 5rem;
      height: 5rem;
      border-radius: rs(4) rs(4) 0 0;
      width: rs(20);
      height: rs(20);
      font-size: rs(16);
      transform: translate(-50%, -70%);

      a.modal--close {
        right: rs(24);
        top: rs(24);
      }
    }
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
  }

  .video.-margin {
    margin-bottom: 3.6rem;

    @include small-up {
      margin-bottom: rs(36);
    }
  }

  &--close {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    background: transparent;

    img {
      width: 2.8rem;
      height: 2.8rem;
      transition: transform $basic-trans-300;
      filter: invert(6%) sepia(3%) saturate(179%) hue-rotate(314deg)
        brightness(96%) contrast(85%);
    }

    &:hover img {
      transform: rotate(90deg);
    }

    @include small-up {
      width: rs(36);
      height: rs(36);

      img {
        width: rs(28);
        height: rs(28);
      }
    }
  }

  &__content {
    padding: 3.6rem;
    overflow: hidden;
    text-align: left;

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 3.6rem;

      & > div {
        flex: 1;
      }

      .text {
        order: 1;

        @include small-up {
          order: unset;
        }
      }

      .media {
        overflow: hidden;

        .flicking-viewport:not(.vertical) {
          height: auto;
        }

        .video {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &__title {
      text-transform: uppercase;
      color: #0d6191;
      margin: 0 0 2.4rem 0;
      padding-top: 1.875rem;
      width: fit-content;
      position: relative;

      &::before {
        background: #4aa846;
        width: 9%;
        min-width: 2.5rem;
        content: "";
        position: absolute;
        height: 0.3125rem;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }

      @include small-up {
        padding-top: rs(20);
        width: fit-content;
        position: relative;

        &::before {
          width: 10%;
          min-width: rs(40);
          height: rs(4);
        }
      }
    }

    .img__wrapper.-single {
      overflow: hidden;
      border-radius: 0.4rem;
      width: 100%;
      // aspect-ratio: 16 / 9;
      display: flex;
      align-items: center;
      justify-items: center;
      transition: filter 0.2s ease-out;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        pointer-events: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__gallery {
      overflow: hidden;
      cursor: grab;
      overflow: hidden;

      .img__wrapper {
        width: 25rem;
        min-height: 15rem;
        max-height: 25rem;
        margin-right: 1.6rem;
        // margin: 2.4rem 1.6rem;
        overflow: hidden;
        border-radius: 0.4rem;

        &.-single {
          width: 100%;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          pointer-events: none;
          transition: all $basic-trans-200;
        }

        &:hover {
          cursor: pointer;
        }
      }

      :active {
        cursor: grabbing;
      }
    }

    iframe {
      aspect-ratio: 16 / 9;
      width: 100%;
    }

    .button {
      background: #4aa846;
      margin-top: 3.6rem;
      color: var(--primary-color-darker);
      display: block;
      white-space: nowrap;
      transition: all $basic-trans-200;
      width: fit-content;
      max-width: 100%;
      text-overflow: ellipsis;

      img {
        height: 2.4rem;
        vertical-align: middle;
        margin-left: 0;
        transition: margin $basic-trans-200;
        margin-bottom: 0.2rem;
      }

      &:hover {
        background: rgb(75, 138, 25);
        color: $color-text;
      }
    }

    .button {
      color: $color-white;

      &:hover {
        color: $color-white;
      }
    }

    @include small-up {
      padding: rs(36);

      &__title {
        margin: 0 0 rs(36) 0;
      }

      &__gallery .img__wrapper {
        width: rs(350);
        min-height: rs(150);
        max-height: rs(250);
        // margin: rs(24) rs(16);
        margin-right: rs(16);
        border-radius: rs(4);
      }

      &__inner {
        flex-direction: row;
        justify-content: space-between;
        gap: rs(36);

        .button {
          margin-top: rs(36);

          img {
            height: rs(24);
          }
        }
      }
    }
  }
}

.flicking-camera {
  display: inline-flex;
}
</style>
